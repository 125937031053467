import { BaseURL } from '../config/BaseURL';
import authHeader from '../config/auth';
import interceptors from '../config/Interceptors';

export const AdminProfile = (param) => {
    return interceptors()?.post(BaseURL + "/superAdminAccess/viewSuperAdmin",param, { headers: authHeader() })
}

export const AdminProfileEdit = (param) => {
    return interceptors()?.post(BaseURL + "/superAdminAccess/updateSuperAdmin", param, { headers: authHeader() })
}

export const ChangeProfilePassword = (param) =>{
    return interceptors()?.post(BaseURL + "/superAdminAccess/changepassword", param, { headers: authHeader() })
}