// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_MAIN = "/app/superadmin";
const ROOTS_AUTH = "/auth";

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_MAIN,
  dashboard: path(ROOTS_MAIN, "/dashboard"),
  institute: {
    add: path(ROOTS_MAIN, "/institute/add"),
    edit: path(ROOTS_MAIN, "/institute/edit"),
    list: path(ROOTS_MAIN, "/institute/list"),
    view: path(ROOTS_MAIN, "/institute/view"),
  },
  manage_admins: {
    add: path(ROOTS_MAIN, "/manage-admins/add"),
    edit: path(ROOTS_MAIN, "/manage-admins/edit"),
  },
  manage_teacher_group_leader: {
    add: path(ROOTS_MAIN, "/manage-teacher-group-leader/add"),
    edit: path(ROOTS_MAIN, "/manage-teacher-group-leader/edit"),
  },
  manage_class_parents: {
    add: path(ROOTS_MAIN, "/manage-class-parents/add"),
    edit: path(ROOTS_MAIN, "/manage-class-parents/edit"),
  },
  group: {
    add: path(ROOTS_MAIN, "/group/add"),
    view: path(ROOTS_MAIN, "/group/view"),
    list: path(ROOTS_MAIN, "/group/list"),
    addmember: path(ROOTS_MAIN, "/group/addmember"),
    edit: path(ROOTS_MAIN, "/group/edit"),
  },
  member: {
    add: path(ROOTS_MAIN, "/member/add"),
    view: path(ROOTS_MAIN, "/member/view"),
    list: path(ROOTS_MAIN, "/member/list"),
    edit: path(ROOTS_MAIN, "/member/edit"),
  },
  profile: {
    view: path(ROOTS_MAIN, "/profile/view"),
    edit: path(ROOTS_MAIN, "/profile/edit"),
  },
  billing_summary: {
    view: path(ROOTS_MAIN, "/billing-summary/view"),
  },
  campaign_manager: {
    view: path(ROOTS_MAIN, "/campaign-manager/view"),
    edit: path(ROOTS_MAIN, "/campaign-manager/edit"),
  },
  calendar: {
    view: path(ROOTS_MAIN, "/calendar/view"),
  },
  settings: {
    view: path(ROOTS_MAIN, "/settings/view"),
  },
  revenue_report: {
    list: path(ROOTS_MAIN, "/revenue-report/list"),
  },

  tutorial: {
    list: path(ROOTS_MAIN, "/tutorial/list"),
    add: path(ROOTS_MAIN, "/tutorial/add"),
    edit: path(ROOTS_MAIN, "/tutorial/edit"),
  },

  cms: {
    list: path(ROOTS_MAIN, "/cms/list"),
    edit: path(ROOTS_MAIN, "/cms/edit"),
  },

  email: {
    list: path(ROOTS_MAIN, "/email-template/list"),
    edit: path(ROOTS_MAIN, "/email-template/edit"),
  },

  contact: {
    list: path(ROOTS_MAIN, "/contact/list"),
  },

  terms_and_conditions: {
    view: path(ROOTS_MAIN, "/terms-and-conditions/view"),
  },

  privacy_policy: {
    view: path(ROOTS_MAIN, "/privacy-policy/view"),
  },
  contact_us: {
    view: path(ROOTS_MAIN, "/contact-us/view"),
  },
  post: {
    create: path(ROOTS_MAIN, "/post/create"),
    view: path(ROOTS_MAIN, "/post/view"),
    edit: path(ROOTS_MAIN, "/post/edit"),
  },
  payment: path(ROOTS_MAIN, "/payment"),

  super_admin: {
    create: path(ROOTS_MAIN, "/super-admin/create"),
    edit: path(ROOTS_MAIN, "/super-admin/edit"),
  },
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
};
