import React, { useState ,useRef,useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getAllNotification , markNotificationSeen , notificationCountApi } from '../Api/Notification';
import { useDispatch, useSelector } from "react-redux";
import { SweetAlert } from '../components/Swal';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { renderDate, renderTime } from '../renderDateTime';



const NotificationList = () => {
  // State to manage whether the notification list is open or closed
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);
  const bellRef = useRef(null);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [data, setData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Toggle function to open/close the notification list
  const toggleNotificationList = async() => {
  
      const response = await getAllNotification();
      if (response?.status === 201) {
        setData(response?.data?.data);
      } 

  };


  const dismissNotification = async (data) => {

    const response = await markNotificationSeen(data);
    if(notificationCount){
      setNotificationCount(notificationCount-1);
    }
    toggleNotificationList();
  
  };



  const handleClickOutside = (event) => {
    if (
      notificationRef?.current &&
      !notificationRef?.current?.contains(event?.target) &&
      bellRef?.current &&
      !bellRef?.current?.contains(event?.target)
    ) {
      setIsOpen(false);
    }
  };



  
  function handleRemindNotification(postid) {

  
  

      // Swal.fire({
      //   title: 'Remind Notification',
      //   text: 'Do you want to proceed?',
      //   showCancelButton: true,
      //   confirmButtonText: 'Remind again',
      //   cancelButtonText: 'Dismiss'
      // }).then((result) => {
      //   if (result.isConfirmed) {
        
      //   } else if (result.dismiss === Swal.DismissReason.cancel) {
        
      //   }
      // });
    
  }





  // const unsubscribe = onMessageListener().then((payload) => {
  //   setNotification({
  //     title: payload?.notification?.title,
  //     body: payload?.notification?.body,
  //   });

  
  //   toast.success(<div onClick={() => handleNotificationClick(payload?.data?.type)}>
  //   <p>{payload?.notification?.title}</p>
  //   </div>, {
  //     duration: 10000, 
  //     position: 'top-right',  
  //   });

  //   setNotificationCount(notificationCount+1);  

  // });

  // unsubscribe.catch((err) => console.log('failed: ', err));



  useEffect(() => {

    document.addEventListener('click', handleClickOutside);

    const asyncFunction = async () => {
      try {
        const response = await notificationCountApi();
        setNotificationCount(response?.data?.data);
      } catch (error) {
        console.error("Error fetching notification count:", error);
      }
    };
  
    asyncFunction();

    


    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);






  return (
<div class="notification-box">
                  <a class="notifications-btn open" onClick={()=>{toggleNotificationList(); setIsOpen(!isOpen);}} ref={bellRef}><NotificationsIcon/> {notificationCount>0 &&  <small className='noti-content'>{notificationCount}</small>} </a>
                
                  {isOpen && (
                  <div class="notification-blog"  ref={notificationRef} >
                     <div class="notification-blog-head">
                        <h4>Notifications</h4>  
                     </div>
                     
                     <div class="notification-blog-body">
                        <ul class="notification-list">

       {data && data.length > 0 ? (
          data.map((notification) => (


                           <li key={notification.id}>
                            <div class="notification-list-title" onClick={()=>{ notification.type && handleRemindNotification(notification.postid); }}>
                                 <h4>{notification?.title}</h4>
                                 <small>{renderDate(notification?.created_at)}</small>
                              
                              </div>
                          
                              
                              <div style={{textAlign: "right"}}> 
                             <a className='notification-dismiss' onClick={()=>{dismissNotification(notification)}}>Dismiss</a>
                              </div>
                           </li> 
                        ))
                        ) : (
                          <li>
                            <p>No notifications Found.</p>
                          </li>
                        )}

                        
                        </ul>
                     </div>
                  </div> )}


               </div>
  );




  // return (
  //   <div className="notification-container">

  //     <Toaster />
  //     <div onClick={()=>{toggleNotificationList(); setIsOpen(!isOpen);}} className="notifications-btn"  ref={bellRef} style={{ cursor: 'pointer' }}>
  //               <i className="ti ti-bell"></i>
  //             {notificationCount>0 &&  <small className='noti-content'>{notificationCount}</small>} 
  //      </div>

  //     {/* Notification list - Displayed when isOpen is true */}
  //     {isOpen && (
  //       <div className="notification-list" ref={notificationRef}  style={{
  //           position: 'absolute',
  //           border: '1px solid #fbf3f3',
  //           background: 'white',
  //           padding: '16px',
  //           marginTop: '24px',
  //           right: '115px',
  //           width: '430px',
  //           height: '300px',
  //           overflow: 'auto',
        
  //         }}>
  //         <h5 style={{marginBottom: '10px'}}>Notifications</h5>
  //         <ul className='notification-list'>
  //         {data && data.length > 0 ? (
  //             data.map((notification) => (
  //               <li onClick={()=>{
                  
  //                 if(notification.type=='remind'){
  //                   handleRemindNotification();
  //                 }  
                
                
  //               }} key={notification.id} style={{
  //                 borderBottom: "1px solid #e9e9e9",
  //                 padding: "7px 0px"}}>
  //                 <div className='title-noti-head d-flex justify-content-between'>
  //                   <p>{notification?.title}</p>
  //                   <p>{renewDateFormat(notification?.created_at)}</p>
  //                 </div>
  //                 <div style={{textAlign: "right"}}> 
  //                   <a className='notification-dismiss' onClick={()=>{dismissNotification(notification)}}>Dismiss</a>
  //                 </div>
  //               </li>
  //             ))
  //           ) : (
  //             <li>
  //               <p>No notifications Found.</p>
  //             </li>
  //           )}
  //         </ul>
  //       </div>
  //     )}
  //   </div>
  // );
};

export default NotificationList;