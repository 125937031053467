// export const BaseURL = "https://api-test.notetifyapp.com:17365/api/v1"
// export const ImgURL = "https://api-test.notetifyapp.com:17365"

export const BaseURL = "https://notetifyapp.devtechnosys.tech:17162/api/v1"
export const ImgURL = "https://notetifyapp.devtechnosys.tech:17162"

// export const BaseURL = "http://172.16.2.149:17162/api/v1"
// export const ImgURL = "http://172.16.2.149:17162"


// export const BaseURL = "http://172.16.1.250:17162/api/v1"
// export const ImgURL = "http://172.16.1.250:17162"

