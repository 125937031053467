import store from "../Store/Store";

const authHeader = () => {
  const auth = store?.getState()?.login?.data?.token;
  // const auth = useSelector((state)=> state.login && state.login.data && state.login.data.token ? state.login.data.token : null)

  if (auth) {
    return { authorization: "Bearer " + auth };
  }
  return {};
};
export default authHeader;

