import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../components/@material-extend';
// API
import { Login } from '../../Api/Auth';
// Actions
import { cleanLoginData } from '../../Actions/ActionCreators';
import { SweetAlert } from '../../components/Swal';
import { VALIDATE } from '../../utils/mock-data/Constants';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginErr = useSelector(state => state?.login && 
    state?.login?.error && state?.login?.error?.message ?
    state?.login?.error?.message : null);
  
  const LoginMsg = useSelector(state => state?.login && 
    state?.login?.data && state?.login?.data?.token ?
    state?.login?.data : {});
  useEffect(() =>{
    new Promise((resolve, reject) =>{
      if(LoginErr!==null && typeof(LoginErr)==='string'){
        SweetAlert({ title: "Error", text: LoginErr });
        // enqueueSnackbar(LoginErr, {
        //   variant: 'error',
        //   action: (key) => (
        //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //       <Icon icon={closeFill} />
        //     </MIconButton>
        //   )           
        // });
      }
      resolve()
    }).then(()=>{
      dispatch(cleanLoginData())
    }).catch((err)=>{
      console?.log(err);
    })
  }, [LoginErr])

  const LoginSchema = Yup?.object()?.shape({
    email: VALIDATE.EMAIL,
    password: Yup?.string()?.required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {        
        dispatch(Login(values));    
        if(Object.keys(LoginMsg)?.length){
          SweetAlert({ title: "Success", text: `${LoginMsg?.name} Logged in SuccessFully` });
          // enqueueSnackbar(`${LoginMsg.name} Logged in SuccessFully`, {
          //   variant: 'success',
          //   action: (key) => (
          //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          //       <Icon icon={closeFill} />
          //     </MIconButton>
          //   )          
          // });
        }
        if (isMountedRef?.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef?.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors?.afterSubmit && <Alert severity="error">{errors?.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="email"            
            label="Email Address"
            {...getFieldProps('email')}
            error={Boolean(touched?.email && errors?.email)}
            helperText={touched?.email && errors?.email}            
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched?.password && errors?.password)}
            helperText={touched?.password && errors?.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
