import React from "react";
import LoginRoutes from "./loginRoutes";
import MainRoutes from "./mainRoutes";
import { useSelector } from "react-redux";


const Routing = () =>{
    const auth = useSelector((state)=> state?.login && state?.login?.data && state?.login?.data?.token ? true : false)
    
    return(
        <>
        {
            auth  ? <MainRoutes /> : <LoginRoutes />
        }
        
        </>

    )
}

export default Routing;