import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes?.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme?.palette?.primary.light;
  const PRIMARY_MAIN = theme?.palette?.primary.main;
  const PRIMARY_DARK = theme?.palette?.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>      
      <svg width="100" height="100%" viewBox="0 0 1720 720" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M768.462 264.837C768.462 205.892 726.434 169.26 671.731 169.26C617.362 169.26 575 205.892 575 264.837C575 323.781 616.361 360.414 671.064 360.414C725.767 360.414 768.462 323.781 768.462 264.837ZM633.039 264.837C633.039 233.2 651.051 218.547 671.731 218.547C691.744 218.547 710.424 233.2 710.424 264.837C710.424 296.141 691.411 311.127 671.064 311.127C650.384 311.127 633.039 296.141 633.039 264.837Z" fill="#3D4CD2"/>
        <path d="M814.399 293.144C814.399 341.432 841.417 357.75 880.11 357.75H909.129V309.462H888.782C876.107 309.462 871.437 304.799 871.437 293.81V219.213H908.795V171.924H871.437V126.633H814.399V171.924H791.717V219.213H814.399V293.144Z" fill="#3D4CD2"/>
        <path d="M1029.63 214.884C1048.64 214.884 1064.65 226.539 1064.65 246.854H993.268C996.604 226.206 1010.95 214.884 1029.63 214.884ZM1118.69 296.141H1057.98C1053.31 306.131 1044.3 314.124 1028.29 314.124C1009.95 314.124 994.936 302.801 992.935 278.491H1122.02C1122.69 272.829 1123.02 267.168 1123.02 261.84C1123.02 205.226 1084.66 169.26 1030.29 169.26C974.923 169.26 936.23 205.892 936.23 264.837C936.23 323.781 975.59 360.414 1030.29 360.414C1076.66 360.414 1109.68 332.44 1118.69 296.141Z" fill="#3D4CD2"/>
        <path d="M1168.79 293.144C1168.79 341.432 1195.81 357.75 1234.5 357.75H1263.52V309.462H1243.17C1230.5 309.462 1225.83 304.799 1225.83 293.81V219.213H1263.19V171.924H1225.83V126.633H1168.79V171.924H1146.11V219.213H1168.79V293.144Z" fill="#3D4CD2"/>
        <path d="M1301.96 357.75H1359V171.924H1301.96V357.75ZM1330.65 152.609C1350.66 152.609 1364.34 138.955 1364.34 121.971C1364.34 104.654 1350.66 91 1330.65 91C1310.3 91 1296.62 104.654 1296.62 121.971C1296.62 138.955 1310.3 152.609 1330.65 152.609Z" fill="#3D4CD2"/>
        <path d="M1412.68 357.75H1469.71V219.213H1500.4V171.924H1469.71V170.259C1469.71 150.611 1477.39 143.95 1499.4 145.282V96.9944C1442.7 93.9972 1412.68 117.975 1412.68 166.596V171.924H1391.99V219.213H1412.68V357.75Z" fill="#3D4CD2"/>
        <path d="M1619.6 288.148L1576.57 171.924H1512.86L1588.58 351.422L1545.88 446H1607.26L1724 171.924H1662.29L1619.6 288.148Z" fill="#3D4CD2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M56.1025 102.889C13.6724 116.464 -9.71788 161.86 3.85889 204.283L78.9447 438.904C92.5215 481.328 137.917 504.717 180.347 491.142L489.92 392.1C532.35 378.526 555.715 333.117 542.139 290.694L467.065 56.111C453.488 13.6876 408.08 -9.71822 365.649 3.85641L56.1025 102.889ZM275.276 249.828C276.808 249.337 277.652 247.699 277.162 246.167C276.672 244.635 275.033 243.791 273.501 244.281L270.022 245.394C268.88 245.76 268.25 246.982 268.616 248.124L268.841 248.829C269.332 250.361 270.971 251.205 272.503 250.715L275.276 249.828Z" fill="#3D4CD2"/>
        <path d="M479.947 471.527L339.481 436.763L454.914 395.047L479.947 471.527Z" fill="#3D4CD2"/>
        <path d="M330.091 121V266.62L234.909 121H178V356H234.909V211.05L330.091 356H387V121H330.091Z" fill="white"/>
      </svg>
    </Box>
  );
}
