import React from 'react';
import { Provider } from 'react-redux';

import store from './Store/Store';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import '../src/style.css';
// components
// import Settings from './components/settings';
// import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
import withClearCache from './clearCache';

// ----------------------------------------------------------------------

function MainApp(props) {
  store?.subscribe(() => {
    localStorage?.setItem('reduxStore', JSON?.stringify(store?.getState()));
  })

  return (
    <Provider store={store}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <NotistackProvider>
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Provider>
  );
}

// Wrap MainApp with cache clearing functionality
const ClearCacheComponent = withClearCache(MainApp);

export default function App() {
  return <ClearCacheComponent />;
}

// export default function App() {
//   store.subscribe(() => {
//     localStorage.setItem('reduxStore', JSON.stringify(store.getState()));

//   })


//   return (
//     <Provider store={store}>
//       <ThemeConfig>
//         <ThemePrimaryColor>
//           <NotistackProvider>
//             <ScrollToTop />
//             <Router />
//           </NotistackProvider>
//         </ThemePrimaryColor>
//       </ThemeConfig>
//     </Provider>
//   );
// }

