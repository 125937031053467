import { format, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";

// render date
export const renderDate = (date) => {
  let d = new Date(date);

  return (d?.getMonth() + 1 < 10 ? "0" + (d?.getMonth() + 1) : d?.getMonth() + 1) + "-" + (d?.getDate() < 10 ? "0" + d?.getDate() : d?.getDate()) + "-" + d?.getFullYear();
};
// render time
export const renderTime = (time) => {
  let d = new Date(time);
  let hours = d?.getHours() > 12 ? d?.getHours() - 12 : d?.getHours();
  hours = hours < 10 ? "0" + hours : hours;

  return hours + ":" + (d?.getMinutes() < 10 ? "0" + d?.getMinutes() : d?.getMinutes()) + (d?.getHours() < 12 ? " AM" : " PM");
};
// render date and time
export const renderDateTime = (dt) => {
  let d = new Date(dt);
  let hours = d?.getHours() > 12 ? d?.getHours() - 12 : d?.getHours();
  hours = hours < 10 ? "0" + hours : hours;

  return (
    (d?.getMonth() + 1 < 10 ? "0" + (d?.getMonth() + 1) : d?.getMonth() + 1) +
    "-" +
    (d?.getDate() < 10 ? "0" + d?.getDate() : d?.getDate()) +
    "-" +
    d?.getFullYear() +
    " " +
    hours +
    ":" +
    (d?.getMinutes() < 10 ? "0" + d?.getMinutes() : d?.getMinutes()) +
    (d?.getHours() < 12 ? " AM" : " PM")
  );
};
// date format
export const FORMAT_DATE = (date) => {
  let d = new Date(date);
  return (d?.getMonth() + 1 < 10 ? "0" + (d?.getMonth() + 1) : d?.getMonth() + 1) + "-" + (d?.getDate() < 10 ? "0" + d?.getDate() : d?.getDate()) + "-" + d?.getFullYear();
};

export const renewDateFormat = (date) => {
  const formattedDate = format(parseISO(date), "MM-dd-yyyy", { locale: enUS });
  return formattedDate;
};

export default function DateFormat(date, post = false) {
  if (post) {
    return date ? format(new Date(date), "MM/dd/yyyy hh:mm aa") : "-";
  }
  return date ? format(new Date(date), "MM-dd-yyyy") : "-";
}
