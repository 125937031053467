import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Divider, MenuItem, Typography, Tooltip } from '@mui/material';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
// 
import { cleanAllData } from '../../Actions/ActionCreators';
import { useDispatch, useSelector } from 'react-redux';
// 
import LogoutIcon from '@mui/icons-material/Logout';
import { AdminProfile } from '../../Api/Profile';
import { ImgURL } from '../../config/BaseURL';
import { PATH_DASHBOARD } from '../../routes/paths';
import NotificationList from '../../components/NotificationList';

// ----------------------------------------------------------------------
const MENU_OPTIONS = [
  { label: 'Home', icon: homeFill, linkTo: PATH_DASHBOARD.dashboard },
  { label: 'Profile', icon: personFill, linkTo: PATH_DASHBOARD.profile.view },
  { label: 'Settings', icon: settings2Fill, linkTo: PATH_DASHBOARD.settings.view },
];
// ----------------------------------------------------------------------
export default function AccountPopover() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const logindata = useSelector(state => state?.login?.data?.user);
  // console.log("logindata:::", logindata);

  const getData = async () => {
    const param = {
      id: logindata?.id
    }
    const resp = await AdminProfile(param);
    // console.log(resp.data.data);
    setData(resp?.data?.data);
  }

  useEffect(() => {
    getData();
  }, []);

  const { id, firstname, lastname, email, profilepic } = useSelector(state => state?.login?.data?.user)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(cleanAllData())
  }

  return (
    <>

<NotificationList></NotificationList>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme?.palette?.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt="Super Admin Profile Picture" src={ImgURL + "/" + profilepic} />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef?.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
            {firstname + " " + lastname}
          </Typography>
          <Tooltip title={email}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {email}
            </Typography>
          </Tooltip>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS?.map((option) => (
          <MenuItem
            key={option?.label}
            to={option?.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option?.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option?.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={() => { handleLogout() }}
            startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
