import axios from 'axios';
import { loginFailure, loginSuccess } from '../Actions/ActionCreators';
import authHeader from '../config/auth';
import { BaseURL } from '../config/BaseURL';

export const Login = (values) => {
    return (dispatch) => {
        axios?.post(BaseURL + "/superAdminAccess/login", values)?.then((res) => {
            let temp = {
                user: res?.data?.data,
                token: res?.headers?.authorization
            }
            dispatch(loginSuccess(temp))
        }).catch((err) => {
            if (!err?.response) {
                alert("Something Went wrong : Please Check Network or Server Connectivity")
            }
            else {
                dispatch(loginFailure(err?.response?.data));
            }
        })
    }
}

export const ForgotPassword = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/forgotPassword", data)
}

export const ResetPassword = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/resetpassword", data)
}

export const ResendLink = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/resendlink", data);
}

export const AddMoreSuperAdmin = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/addSuperAdmin", data, { headers: authHeader() })
}

export const GetAllSuperAdmin = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/getAllSuperAdmin", data, { headers: authHeader() })
}

export const DeleteSuperAdmin = (data) => {
    return axios?.post(BaseURL + "/superAdminAccess/deleteSuperAdmin", data, { headers: authHeader() })
}

export const terms_and_conditions = (data) => {
    return axios?.post(BaseURL +`/schoolAdminCms/getAllCms`, data)
}
