import { useState } from "react";
// import { Tabs, Tab } from "react-bootstrap";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Tab, Tabs, TextField } from "@mui/material";
import { Error, Note, Success } from "../../utils/helpers/Alert";
import { MESSAGE } from "../../utils/helpers/ValidationMessage";
import Page from "../../components/Page";
import { Finances } from "../../Api/Finance";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CARD_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

const validationSchema = Yup.object().shape({
  fname: Yup.string().strict(true).trim(MESSAGE.NO_SPACE).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
  lname: Yup.string().strict(true).trim(MESSAGE.NO_SPACE).max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED),
  financial_info: Yup.string(),
  account_holder_type: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  routing_number: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string()
      .required(MESSAGE.REQUIRED)
      .matches(/^[0-9]{9}$/, "Invalid routing number"),
    otherwise: Yup.string().strip(),
  }),
  account_number: Yup.string().when("financial_info", {
    is: "bank",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  cnfrm_account_number: Yup.string().when(["financial_info", "account_number"], {
    is: (financial_info, account_number) => financial_info === "bank" && account_number,
    then: Yup.string()
      .oneOf([Yup.ref("account_number")], "Account numbers must match")
      .required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  card_no: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().min(16, "Invalid card number").max(16, "Invalid card number").matches(CARD_REGEX, "Invalid card number").required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  cvv: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().min(3, "Invalid cvv").max(3, "Invalid cvv").required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
  exp_date: Yup.string().when("financial_info", {
    is: "card",
    then: Yup.string().required(MESSAGE.REQUIRED),
    otherwise: Yup.string().strip(),
  }),
});

const FinanceForm = () => {
  const [key, setKey] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const initialValues = {
    instituteid: state.instituteid,
    financial_info: "bank",
    fname: "",
    lname: "",
    account_number: "",
    cnfrm_account_number: "",
    cvv: "",
    exp_date: null,
    routing_number: "",
    account_holder_type: "",
    card_no: "",
    type: "add",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      Finances({ ...values, stripe_customer_id: "adminInfo.stripe_customer_id" })
        .then((res) => {
          Swal.fire({ text: res?.data?.message, ...Success });
          navigate(-1);
        })
        .catch((err) => {
          const { message } = err?.response?.data;
          Swal.fire({ text: message ? message : Note?.Err, ...Error });
          console?.log(err);
          setSubmitting(false);
        });
    },
  });

  const { values, touched, errors, getFieldProps } = formik;

  const fxTab = (k) => {
    console?.log(k);
    setKey(k);
  };

  const handleChange = (event, newValue) => {
    setKey(newValue);
    formik?.resetForm();
    formik?.setFieldValue("financial_info", newValue ? "card" : "bank");
  };

  const today = new Date();
  const minDate = new Date(today?.getFullYear(), today?.getMonth());

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
    },
  };

  return (
    <Page>
      <Card>
        <CardHeader title="Add Financial Information" />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Tabs value={key} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Bank Account" />
                <Tab label="Debit Card" />
              </Tabs>

              {key === 0 && (
                <Grid container spacing={3} marginTop={3}>
                  <Grid item xs={12} md={12}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel id="demo-simple-select-outlined-label">Account Holder Type</InputLabel>
                      <Select MenuProps={MenuProps} input={<OutlinedInput label="Account Holder Type" />} {...getFieldProps("account_holder_type")}>
                        <MenuItem value="individual">Individual</MenuItem>
                        <MenuItem value="company">Company</MenuItem>
                      </Select>
                    </FormControl>
                    <ErrorMessage component="p" className="text-danger" name="account_holder_type" />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Account Holder First Name" error={Boolean(touched?.fname && errors?.fname)} {...getFieldProps("fname")} />
                    <FormHelperText error={Boolean(touched?.fname && errors?.fname)} sx={{ marginLeft: "14px" }}>
                      {touched?.fname && errors?.fname}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Account Holder Last Name" error={Boolean(touched?.lname && errors?.lname)} {...getFieldProps("lname")} />
                    <FormHelperText error={Boolean(touched?.lname && errors?.lname)} sx={{ marginLeft: "14px" }}>
                      {touched?.lname && errors?.lname}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Routing Number" error={Boolean(touched?.routing_number && errors?.routing_number)} {...getFieldProps("routing_number")} />
                    <FormHelperText error={Boolean(touched?.routing_number && errors?.routing_number)} sx={{ marginLeft: "14px" }}>
                      {touched?.routing_number && errors?.routing_number}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Account Number" error={Boolean(touched?.account_number && errors?.account_number)} {...getFieldProps("account_number")} />
                    <FormHelperText error={Boolean(touched?.account_number && errors?.account_number)} sx={{ marginLeft: "14px" }}>
                      {touched?.account_number && errors?.account_number}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Confirm Account Number" error={Boolean(touched?.cnfrm_account_number && errors?.cnfrm_account_number)} {...getFieldProps("cnfrm_account_number")} />
                    <FormHelperText error={Boolean(touched?.cnfrm_account_number && errors?.cnfrm_account_number)} sx={{ marginLeft: "14px" }}>
                      {touched?.cnfrm_account_number && errors?.cnfrm_account_number}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )}

              {key === 1 && (
                <Grid container spacing={3} marginTop={3}>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="First Name" error={Boolean(touched?.fname && errors?.fname)} {...getFieldProps("fname")} />
                    <FormHelperText error={Boolean(touched?.fname && errors?.fname)} sx={{ marginLeft: "14px" }}>
                      {touched?.fname && errors?.fname}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Last Name" error={Boolean(touched?.lname && errors?.lname)} {...getFieldProps("lname")} />
                    <FormHelperText error={Boolean(touched?.lname && errors?.lname)} sx={{ marginLeft: "14px" }}>
                      {touched?.lname && errors?.lname}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="Card Number" error={Boolean(touched?.card_no && errors?.card_no)} {...getFieldProps("card_no")} />
                    <FormHelperText error={Boolean(touched?.card_no && errors?.card_no)} sx={{ marginLeft: "14px" }}>
                      {touched?.card_no && errors?.card_no}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth required label="CVV" error={Boolean(touched?.cvv && errors?.cvv)} {...getFieldProps("cvv")} />
                    <FormHelperText error={Boolean(touched?.cvv && errors?.cvv)} sx={{ marginLeft: "14px" }}>
                      {touched?.cvv && errors?.cvv}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Expiration Date"
                        value={values.exp_date}
                        inputFormat="MM/yyyy"
                        minDate={new Date()}
                        views={["month", "year"]}
                        onChange={(newValue) => {
                          let date = new Date(newValue);
                          let dateVal = date?.toISOString();
                          formik?.setFieldValue("exp_date", dateVal);
                        }}
                        renderInput={(params) => <TextField fullWidth onKeyDown={(e) => e?.preventDefault()} {...params} />}
                      />
                    </LocalizationProvider>
                    <ErrorMessage component="p" className="error-msg" name="exp_date" />
                  </Grid>
                </Grid>
              )}

              <Stack spacing={2} mt={3} direction="row" alignItems="center" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" disabled={formik?.isSubmitting}>
                  Add
                </Button>
                <Button type="button" variant="outlined" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
    </Page>
  );
};

export default FinanceForm;
