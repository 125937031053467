// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { MonetizationOnOutlined } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import ContactsIcon from '@mui/icons-material/Contacts';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard },

  //   ]
  // },
  {
    subheader: '',
    items: [
      { title: 'Profile', path: PATH_DASHBOARD?.profile?.view, icon: <AccountCircleIcon /> },

      { title: 'Manage Institutions', path: PATH_DASHBOARD?.institute?.list, icon: <AccountBalanceIcon /> ,
      // children:[
      //   { title: 'Manage Groups', path: PATH_DASHBOARD.group.list },
      //   { title: 'Manage Members', path: PATH_DASHBOARD.member.list },
      //   { title: 'Billing Summary', path: PATH_DASHBOARD.billing_summary.view },
      //   { title: 'Campaign Manager', path: PATH_DASHBOARD.campaign_manager.view },
      //   { title: 'My Calendar', path: PATH_DASHBOARD.calendar.view},
      // ]
    },
    //  { title: 'Billing Summary', path: PATH_DASHBOARD.billing_summary.view, icon : <MonetizationOnOutlined /> },
      { title: 'Settings', path: PATH_DASHBOARD?.settings?.view, icon: <SettingsIcon /> },
      { title: 'Revenue Report', path: PATH_DASHBOARD?.revenue_report?.list, icon: <AssessmentIcon />},
      { title: 'Tutorial', path: PATH_DASHBOARD?.tutorial?.list, icon: <OndemandVideoIcon /> },
      { title: 'CMS Managment', path: PATH_DASHBOARD?.cms?.list, icon: <AppsIcon /> },
      { title: 'Email Template', path: PATH_DASHBOARD?.email?.list, icon: <MailOutlineIcon /> },
      { title: 'Contact Us Submissions', path: PATH_DASHBOARD?.contact?.list, icon: <ContactsIcon /> },

      // { title: 'Privacy Policy', path: PATH_DASHBOARD.privacy_policy.view, icon: <PrivacyTipIcon /> },
      // { title: 'Contact Us', path: PATH_DASHBOARD.contact_us.view, icon: <ContactPhoneIcon /> },

    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

];

export default sidebarConfig;
