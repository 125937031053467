import { BaseURL } from "../config/BaseURL";
import authHeader from "../config/auth";
import interceptors from "../config/Interceptors";

export const ViewSettings = () => interceptors()?.get(BaseURL + "/superAdminInstitute/viewSettings", { headers: authHeader() });

export const AddCampaignPost = (values) => interceptors()?.post(BaseURL + "/superAdminPost/addCampaignPost", values, { headers: authHeader() });

export const ViewAllAddPost = (values) => interceptors()?.post(BaseURL + "/superAdminPost/viewAllAddPost", values, { headers: authHeader() });

export const ApproveAddPost = (values) => interceptors()?.post(BaseURL + "/superAdminPost/approveAddPost", values, { headers: authHeader() });

export const ViewBillingSummary = (values) => interceptors()?.post(BaseURL + "/superAdminPost/viewBillingSummary", values, { headers: authHeader() });

export const Refund = (values) => interceptors()?.post(BaseURL + "/superAdminPost/refund", values, { headers: authHeader() });

export const Finances = (values) => interceptors()?.post(BaseURL + "/superAdminInstitute/instituteFinancialInfo", values, { headers: authHeader() });

export const DeleteAddPost = (values) => interceptors()?.post(BaseURL + "/superAdminPost/deleteAddPost", values, { headers: authHeader() });

export const EditAddPost = (values) => interceptors()?.post(BaseURL + "/superAdminPost/editAddPost", values, { headers: authHeader() });

export const generatePresignedUrl = (values) => interceptors()?.post(BaseURL + "/schoolAdminAccess/generatePresignedUrl", values);

