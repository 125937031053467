import Swal from "sweetalert2";

export const SweetAlert = (props) => {
    return (
        Swal.fire({
            title: props?.title,
            text: props?.text,
            icon: props?.title === 'Success' ? 'success' : 'error',
            // showConfirmButton: false,
            timer: 5000,
        })
    )
}
