import axios from "axios";
import { BaseURL } from "./BaseURL";
import { PATH_AUTH } from '../routes/paths';
import authHeader from "./auth";

export default (history = null) => {
    const axiosInstance = axios?.create({
        baseURL: BaseURL,
        headers: authHeader(),
    });

    axiosInstance?.interceptors?.response?.use(
        (response) => {
            // console.log("got response");
            return response;
        },
        (error) => {
            console?.error(error?.response);
            if (error?.response?.status === 401) {
                // do something
                console?.log("NOT FOUND");
                localStorage?.clear();
                if (history) {
                    history?.push(PATH_AUTH?.login);
                } else {
                    window.location = PATH_AUTH?.login;
                }
            }
            return Promise?.reject(error);
        }
    );

    return axiosInstance;
};