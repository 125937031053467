import React from "react";

// Material
import {styled} from '@mui/styles';
import { 
    Card,
    Typography,
    Container,
    Stack,
    Box,
    Tooltip
 } from "@mui/material";
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import LoginForm from "./LoginForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }   
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 720,
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2)
}));

const ContentStyle = styled(Card)(({ theme }) => ({    
    marginTop: '25%',
    display: 'flex',    
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5),
    boxShadow:theme.customShadows.primary
}));
  
  // ----------------------------------------------------------------------
  
const AuthPage = () =>{

    return(
        <RootStyle title="Login">            
            {/* <MHidden width="mdDown">
                <SectionStyle>                
                <img src="/static/auth/cover.jpg" alt="login" sx={{objectFit:'cover'}} />
                </SectionStyle>
            </MHidden> */}

            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                        <Box sx={{ flexGrow: 1 }}>                        
                        <Typography variant="h4" gutterBottom>
                            Hi, Welcome Back
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                        </Box>
                    </Stack>
                    <LoginForm />
                </ContentStyle>
            </Container>
        </RootStyle>
    )
}

export default AuthPage;