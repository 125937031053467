export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_USER = 'SET_USER';

export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CLEAN_LOGIN_DATA = 'CLEAN_LOGIN_DATA'


export const CLEAN_ALL_DATA = 'CLEAN_ALL_DATA';