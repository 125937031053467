import bell from "../../assets/bell-notify.png";

const Alert = { confirmColor: "#3d4cd2", cancelColor: "#000", timer: 3000 };

export const Delete = { icon: "warning", showCancelButton: true, confirmButtonColor: Alert?.confirmColor, cancelButtonColor: Alert?.cancelColor, confirmButtonText: "Yes", cancelButtonText: "No" };

export const Error = { icon: "error", timer: Alert?.timer, confirmButtonColor: Alert?.confirmColor };

export const Success = { icon: "success", timer: Alert?.timer, confirmButtonColor: Alert?.confirmColor };

export const Info = { icon: "info", timer: Alert?.timer, confirmButtonColor: Alert.confirmColor };

export const Reminder = { color: "#000", imageUrl: bell, showConfirmButton: false, width: "400px", timer: Alert?.timer };

export const Note = {
  MemberCreated: "Member added successfully",
  MemberUpdated: "Member updated successfully",
  MemberDeleted: "Member deleted successfully",
  TeacherCreated: "Teacher added successfully",
  TeacherUpdated: "Teacher updated successfully",
  TeacherDeleted: "Teacher deleted successfully",
  ParentCreated: "Parent added successfully",
  ParentUpdated: "Parent updated successfully",
  ParentDeleted: "Parent deleted successfully",
  AdminCreated: "Admin added successfully",
  AdminDeleted: "Admin deleted successfully",
  AdminUpdated: "Admin updated successfully",
  NoGroup: "There are no groups. Please create a group before adding a member.",
  NoGroupPost: "There are no groups. Please create a group before adding a post.",
  GroupCreated: "Group created successfully",
  GroupUpdated: "Group updated successfully",
  GroupDeleted: "Group deleted successfully",
  GroupArchived: "Archived successfully",
  PostCreated: "Post created successfully",
  PostUpdated: "Post updated successfully",
  PostDeleted: "Post deleted successfully",
  CommentDeleted: "Comment deleted successfully",
  RsvpAdded: "RSVP added successfully",
  RsvpUpdated: "RSVP updated successfully",
  RsvpDeleted: "RSVP deleted successfully",
  DMEdit: "DM updated successfully",
  Err: "Something went wrong!!",
  NoMember: "There are no members selected.",
  NoMemberForDM: "There are no members. Please add members before creating a DM.",
  EnterFinancialInfo: "Financial transactions are not yet enabled. Please enter your bank information here.",
  CompleteFinancialInfoAdmin: "Financial information has not been verified. This post cannot be created yet.",
};
