import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE , CLEAN_ALL_DATA} from "./Types"
import { LOGOUT_LOADING, LOGOUT_SUCCESS, LOGOUT_FAILURE, CLEAN_LOGIN_DATA } from "./Types"
import {SET_USER} from './Types';


export const loginBegin = () => ({
    type : LOGIN_LOADING
 })
 
 export const loginSuccess = (logindata) => ({
    type : LOGIN_SUCCESS,
    logindata ,
 })
 
 export const loginFailure = (loginerror) => ({
    type : LOGIN_FAILURE,
    loginerror
    
 })
 
 export const setUser = (data) => ({
   type : SET_USER,
   data,
})

 
export const logoutBegin = () => ({
    type : LOGOUT_LOADING
 })
 
 export const logoutSuccess = () => ({
    type : LOGOUT_SUCCESS,
 })
 
 export const logoutFailure = () => ({
    type : LOGOUT_FAILURE,
    
 })
 

export const cleanLoginData = () => ({
   type : CLEAN_LOGIN_DATA,
 })
 
 
export const cleanAllData = () => ({
    type : CLEAN_ALL_DATA,
  })
  