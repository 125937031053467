// ----------------------------------------------------------------------

export default function Breadcrumbs(theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme?.spacing(2),
          marginRight: theme?.spacing(2)
        }
      }
    }
  };
}
