// ----------------------------------------------------------------------

export const varHover = {
  hover: { scale: 1.1 }
};

export const varSmallClick = {
  hover: { scale: 1.04 },
  tap: { scale: 0.96 }
};

export const varMediumClick = {
  hover: { scale: 1.1 },
  tap: { scale: 0.9 }
};

export const Reminder = [
  { label: "Select reminder time", value: "" },
  { label: "15 minutes before", value: "15" },
  { label: "30 minutes before", value: "30" },
  { label: "1 hour before", value: "60" },
  { label: "1 day before", value: "1440" },
];