import { BaseURL } from '../config/BaseURL';
import authHeader from '../config/auth';
import interceptors from '../config/Interceptors';

export const getAllNotification = (param) => {
    return interceptors()?.post(BaseURL + "/superAdminCms/getAllNotification",param, { headers: authHeader() })
}

export const markNotificationSeen = (param) => {
    return interceptors()?.post(BaseURL + "/superAdminCms/markNotificationSeen", param, { headers: authHeader() })
}


export const notificationCountApi = (param) => {
    return interceptors()?.post(BaseURL + "/superAdminCms/notificationCount", param, { headers: authHeader() })
}