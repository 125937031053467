import React from "react";
import { useRoutes, Navigate } from "react-router-dom"; 
import AuthLayout from "../layouts/auth";
import LogoLayout from "../layouts/LogoOnlyLayout";
import AuthPage from "../screens/auth";
import ResetPassword from "../screens/auth/ResetPassword";
import ForgotPassword from "../screens/auth/ForgotPassword";


const AuthRouting = () =>{

    return useRoutes([        
        {
            path:'auth',
            element: <LogoLayout />,
            children :[
                { path:'login', element:<AuthPage /> },
                { path:'forgot-password', element:<ForgotPassword /> },
                { path:'reset-password/:token', element:<ResetPassword /> },

                { path: '*', element: <Navigate to="/auth/login" replace /> },
            ]
        },
        { path: '*', element: <Navigate to="/auth/login" replace /> }
    ])
}

export default AuthRouting;