import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// eslint-disable-next-line
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// eslint-disable-next-line
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import FinanceForm from '../screens/billing-summary/FinanceForm';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'app/superadmin',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashBoardScreen /> },

        { path: 'institute/add', element: <InstituteAddScreen /> },
        { path: 'institute/edit', element: <InstituteEditScreen /> },
        { path: 'institute/list', element: <InstituteListScreen /> },
        { path: 'institute/view', element: <InstituteViewScreen /> },


        { path: 'tutorial/list', element: <TutorialListScreen /> },
        { path: 'tutorial/add', element: <TutorialAddScreen /> },
        { path: 'tutorial/edit', element: <TutorialEditScreen /> },


        { path: 'cms/list', element: <CmsListScreen /> },
        { path: 'cms/edit', element: <CmsEditScreen /> },

        { path: 'email-template/list', element: <EmailListScreen /> },
        { path: 'email-template/edit', element: <EmailEditScreen /> },


        { path: 'contact/list', element: <ContactListScreen /> },



        { path: 'manage-admins/add', element: <ManageAdminsAddScreen /> },
        { path: 'manage-admins/edit', element: <ManageAdminsEditScreen /> },

        { path: 'manage-teacher-group-leader/add', element: <ManageTeaderGroupLeaderAddScreen /> },
        { path: 'manage-teacher-group-leader/edit', element: <ManageTeaderGroupLeaderEditScreen /> },

        { path: 'manage-class-parents/add', element: <ManageClassParentsAddScreen /> },
        { path: 'manage-class-parents/edit', element: <ManageClassParentsEditScreen /> },

        { path: 'group/add', element: <GroupAddScreen /> },
        { path: 'group/list', element: <GroupListScreen /> },
        { path: 'group/view', element: <GroupViewScreen /> },
        { path: 'group/addmember', element: <GroupAddMember /> },
        { path: 'group/edit', element: <GroupEditScreen /> },

        { path: 'member/add', element: <MemberAddScreen /> },
        { path: 'member/list', element: <MemberListScreen /> },
        { path: 'member/view', element: <MemberViewScreen /> },
        { path: 'member/edit', element: <MemberEditScreen /> },

        { path: 'profile/view', element: <AdminProfileViewScreen /> },
        { path: 'profile/edit', element: <AdminProfileEditScreen /> },

        { path: 'billing-summary/view', element: <AdminBillingSummaryViewScreen /> },

        { path: 'campaign-manager/view', element: <AdminCampaignManagerViewScreen /> },
        { path: 'campaign-manager/edit', element: <AdminCampaignManagerEditScreen /> },

        { path: 'calendar/view', element: <AdminCalendarViewScreen /> },

        { path: 'settings/view', element: <AdminSettingsViewScreen /> },

        { path: 'revenue-report/list', element: <RevenueListScreen />},

        { path: 'terms-and-conditions/view', element: <AdminTermsAndConditionsViewScreen /> },

        { path: 'privacy-policy/view', element: <AdminPrivacyPolicyViewScreen /> },



        { path: 'post/create', element: <AdminPostCreateScreen /> },
        { path: 'post/view', element: <AdminPostViewScreen /> },
        { path: 'post/edit', element: <AdminPostEditScreen /> },

        { path: 'payment', element: <AdminPaymentScreen /> },

        { path: 'super-admin/create', element: <SuperAdminCreateScreen /> },
        { path: 'super-admin/edit', element: <SuperAdminEditScreen /> },

        { path: 'addfinance', element : <FinanceForm /> },

        { path: '*', element: <Navigate to="/app/superadmin/institute/list" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/app/superadmin/institute/list" replace /> }
  ]);
}

// IMPORT COMPONENTS
const DashBoardScreen = Loadable(lazy(() => import('../screens/dashboard')));
const InstituteAddScreen = Loadable(lazy(() => import('../screens/institute/Add')));
const InstituteEditScreen = Loadable(lazy(() => import('../screens/institute/Edit')));
const InstituteListScreen = Loadable(lazy(() => import('../screens/institute/List')));
const InstituteViewScreen = Loadable(lazy(() => import('../screens/institute/View')));

const TutorialListScreen = Loadable(lazy(() => import('../screens/tutorial/List')));
const TutorialAddScreen = Loadable(lazy(() => import('../screens/tutorial/Add')));
const TutorialEditScreen = Loadable(lazy(() => import('../screens/tutorial/Edit')));

const RevenueListScreen = Loadable(lazy(() => import('../screens/revenue-report/List')))

const CmsListScreen = Loadable(lazy(() => import('../screens/cms/List')));
const CmsEditScreen = Loadable(lazy(() => import('../screens/cms/Edit')));

const EmailListScreen = Loadable(lazy(() => import('../screens/email-template/List')));
const EmailEditScreen = Loadable(lazy(() => import('../screens/email-template/Edit')));


const ContactListScreen = Loadable(lazy(() => import('../screens/contact/List')));


const ManageAdminsAddScreen = Loadable(lazy(() => import('../screens/manage-admins/Add')));
const ManageAdminsEditScreen = Loadable(lazy(() => import('../screens/manage-admins/Edit')));

const ManageTeaderGroupLeaderAddScreen = Loadable(lazy(() => import('../screens/manage-teacher-group-leader/Add')));
const ManageTeaderGroupLeaderEditScreen = Loadable(lazy(() => import('../screens/manage-teacher-group-leader/Edit')));

const ManageClassParentsAddScreen = Loadable(lazy(() => import('../screens/manage-class-parents/Add')));
const ManageClassParentsEditScreen = Loadable(lazy(() => import('../screens/manage-class-parents/Edit')));

const GroupAddScreen = Loadable(lazy(() => import('../screens/group/Add')));
const GroupListScreen = Loadable(lazy(() => import('../screens/group/List')));
// const GroupViewScreen = Loadable(lazy(() => import('../screens/group/Detail')));
const GroupViewScreen = Loadable(lazy(() => import('../screens/group/View')));
const GroupAddMember = Loadable(lazy(() => import('../screens/group/View/MemberAdd')));
const GroupEditScreen = Loadable(lazy(() => import('../screens/group/Edit')));

const MemberAddScreen = Loadable(lazy(() => import('../screens/member/Add')));
const MemberListScreen = Loadable(lazy(() => import('../screens/member/List')));
const MemberViewScreen = Loadable(lazy(() => import('../screens/member/Detail')));
const MemberEditScreen = Loadable(lazy(() => import('../screens/member/Edit')));

const AdminProfileViewScreen = Loadable(lazy(() => import('../screens/profile/View')));
const AdminProfileEditScreen = Loadable(lazy(() => import('../screens/profile/Edit')));

const AdminBillingSummaryViewScreen = Loadable(lazy(() => import('../screens/billing-summary/View')));

const AdminCampaignManagerViewScreen = Loadable(lazy(() => import('../screens/campaign-manager/View')));
const AdminCampaignManagerEditScreen = Loadable(lazy(() => import('../screens/campaign-manager/Edit')));

const AdminCalendarViewScreen = Loadable(lazy(() => import('../screens/calendar/View')));

const AdminSettingsViewScreen = Loadable(lazy(() => import('../screens/settings/View')));



const AdminTermsAndConditionsViewScreen = Loadable(lazy(() => import('../screens/terms-and-conditions/View')));

const AdminPrivacyPolicyViewScreen = Loadable(lazy(() => import('../screens/privacy-policy/View')));



const AdminPostCreateScreen = Loadable(lazy(() => import('../screens/post/Create')));
const AdminPostViewScreen = Loadable(lazy(() => import('../screens/post/View')));
const AdminPostEditScreen = Loadable(lazy(() => import('../screens/post/Edit')));

const AdminPaymentScreen = Loadable(lazy(() => import('../screens/campaign-manager/Payment')));

const SuperAdminCreateScreen = Loadable(lazy(() => import('../screens/settings/superAdmin/CreateSuperAdmin')));
const SuperAdminEditScreen = Loadable(lazy(() => import('../screens/settings/superAdmin/EditSuperAdmin')));