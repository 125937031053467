export const MESSAGE = {
  FIRSTNAME: "Invalid first name",
  LASTNAME: "Invalid last name",
  PHONE: "Invalid phone number",
  EMAIL: "Invalid email address",
  PASSWORD: "Must be minimum 8  characters long and alphanumeric with at least 1 uppercase letter, 1 number and 1 special character",
  STREET: "Invalid street address",
  CITY: "Invalid city",
  STATE: "Invalid state",
  INSTITUTE: "Invalid institute name",
  ACRONYM: "Invalid institute acronym",
  CHILD: "Invalid child name",
  RENEWALPERIOD: "Please select renewal period",
  RENEWALAMOUNT: "Please enter a valid renewal amount",
  RENEWALNOTES: "Invalid renewal notes",
  REQUIRED: "This field is required",
  GROUP_NAME: "Invalid Group name",
  NO_SPACE: "Spaces are not allowed",
  RSVP: "Please allow the RSVP",
  TITLE: "Title should be less than 100 chars",
  GROUPSELECTEDFORPOST: "At least one group should be selected for post",
  DATETIMESELECTED: "Invalid date & time",
  PAIDEVENTAMOUNT: "Please enter a valid amount",
  PRICE: "Please enter a valid price",
  TAX: "Please enter a valid tax amount",
  SIZE: "Please enter a valid size",
};
